<template>
  <div class='s_bf'>
    <div class="m-b-20">
        <a-button type="primary" v-has:deviceAdd="()=>{$router.push('/pdaManage/device/deviceAdd?type=add')}" class="m-r-10">
          <PlusOutlined />
          创建激活码
        </a-button>
        <a-button type="primary" v-has:deviceLog="()=>{logsVisible=true}">
          操作记录
        </a-button>
    </div>
    <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
      :onFinish='pageSearchChange' layout='inline'>
      <a-form-item label="关键词" name='keywords'>
        <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入关键词"></a-input>
      </a-form-item>
      <a-form-item label="状态" name='status'>
        <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="0">
            待激活
          </a-select-option>
          <a-select-option :value="1">
            激活成功
          </a-select-option>
          <a-select-option :value="2">
            激活失败
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="创建时间" name='time'>
        <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
      </a-form-item>
      <a-form-item label="绑定时间" name='time'>
        <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onCreateTimeChange" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType='submit'>搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
      <template v-slot:action="{ record }">
        <a-button type="link" v-has:deviceDel="()=>delCode(record)">删除</a-button>
      </template>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
      :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
      @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </div>
  <!-- 操作记录 -->
  <a-modal v-model:visible="logsVisible" title="操作记录" :width='1200' destroyOnClose centered :footer="null">
    <logs />
  </a-modal>
</template>
<script>
  import { PlusOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import logs from "./logs";
  import {
    deviceList,
    delDeviceCode,
  } from "@/api/pda.js";
  // 初始化默认筛选项数值
  let defSearch = {
    status: "",
    keywords: "",
    active_start_time: '',
    active_end_time: '',
    create_start_time: "",
    create_end_time: "",
    page: 1,
    limit: 10,
  };
  export default {
    components: { logs, PlusOutlined },
    setup(props) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        listLoading: false,
        total: 0
      });
      let columns = [
        {
          title: "关联品牌",
          dataIndex: "brand_name",
          key: "brand_name",
        },
        {
          title: "激活码",
          dataIndex: "active_code",
          key: "active_code",
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "设备号",
          dataIndex: "imei",
          key: "imei",
        },
        {
          title: "绑定类型",
          dataIndex: "active_type_text",
          key: "active_type_text",
        },
        {
          title: "状态",
          dataIndex: "status_text",
          key: "status_text",
        },
        {
          title: "绑定时间",
          dataIndex: "active_time",
          key: "active_time",
        },
        {
          title: "操作",
          key: "action",
          fixed:"right",
          slots: { customRender: "action" },
        },
      ];
      const $Modal = inject("$Modal");
      // 时间筛选
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.create_start_time = e[0];
          search.value.create_end_time = e[1];
        } else {
          search.value.create_start_time = "";
          search.value.create_end_time = "";
        }
      };
      // 时间筛选
      const onCreateTimeChange = (e) => {
        if (e.length > 0) {
          search.value.active_start_time = e[0];
          search.value.active_end_time = e[1];
        } else {
          search.value.active_start_time = "";
          search.value.active_end_time = "";
        }
      };
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };

      const initData = async (values) => {
        state.listLoading = true;
        data.value = [];
        try {
          let res = await deviceList(values).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      // 删除
      const delCode = (data) => {
        $Modal.confirm({
          content: "删除后该激活码将无法恢复，您确定要删除该激活码吗？",
          centered:true,
          onOk: async () => {
            let res = await delDeviceCode(data.id).then((res) => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            } else {
              message.error(res.msg);
            }
          },
        });
      };
      // 操作弹窗
      const logsVisible = ref(false);
      onMounted(() => {
        initData(search.value);
      });

      return {
        ...toRefs(state),
        search,
        data,
        columns,
        onCreateTimeChange,
        onChange,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        delCode,
        logsVisible,
      };
    },
  };
</script>